<template>
    <el-row class="hz-row" :gutter="24">
      <el-col :span="12">
        <!-- <el-button type="primary" @click="addDialog">
          <el-icon style="vertical-align: middle">
            <Plus />
          </el-icon>
        </el-button> -->
      </el-col>
      <el-col :span="4">
      </el-col>
      <el-col :span="8">
        <el-row :gutter="10">
          <el-col :span="8">
            <el-input v-model="searchName" placeholder="输入关键字"></el-input>
          </el-col>
          <el-col :span="2">
            <el-button type="primary" @click="init()">
              <el-icon style="vertical-align: middle">
                <Search />
              </el-icon>
              <span style="vertical-align: middle"> 搜索 </span>
            </el-button>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-divider></el-divider>
    <div>
      <el-table :data="tableData" stripe style="width: 100%">
        <el-table-column prop="logtype" label="类型">
          <template #default="scope">
            <el-tag :type="scope.row.logtype === '信息' ? 'success' : 'danger'" disable-transitions>{{ scope.row.logtype }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="logcontent" label="内容" />
        <el-table-column prop="createTime" label="创建时间" />
        <el-table-column label="操作">
          <template #default="scope">
            <el-button size="small" @click="handleDetails(scope.$index, scope.row)">查看</el-button>
            <el-button size="small" v-hasPer="['log:del']" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagin">
      <el-pagination small background layout="prev, pager, next" :total="total" class="mt-4"
        @current-change="handleCurrentChange" />
    </div>
  </template>
  
  <script>
  import { ElMessage} from 'element-plus'
  export default {
    data() {
      return {
        currentPage: 1,
        pageSize: 10,
        small: false,
        background: true,
        tableData: [],
        total: 0,
        dialogVisible: false,
        dialogTitle: '新增账号',
        searchName:''
      }
    },
    created() {
      this.init();
    },
    methods: {
      init() {
        let _this = this;
        this.$https.get('api/admin/log/getlist', {
          params: {
            page: _this.currentPage,
            limit: _this.pageSize,
            account:_this.searchName
          }
        }).then(res => {
            console.log(res);
          if (res.success) {
            _this.total = res.count;
            _this.tableData = res.data;
          }
        })
      },
      handleDetails(index, item) {
        this.dialogVisible = true;
        this.dialogTitle = '详情'
        this.$nextTick(() => {
          this.$refs.editUser.editForm(item);
        })
      },
      handleDelete(index, item) {
        const _this = this;
        this.$https.get('api/admin/log/del', { params: { id: item.id } }).then(res => {
          if (res.success) {
            ElMessage({
              type: 'success',
              message: res.msg
            })
            _this.init();
          }
        })
      },
      //点击页码事件
      handleCurrentChange(value) {
        this.currentPage = value
        this.init();
      },
      //弹出新增账号界面
      addDialog() {
        this.dialogVisible = true;
        this.dialogTitle = '新增账号'
      },
      //关闭弹窗
      closeDialog() {
        this.dialogVisible = false;
        this.init();
      }
    }
  }
  </script>
  
  <style>
  .pagin {
    margin-top: 10px;
  }
  </style>